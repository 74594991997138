import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { colorTelusPurple } from "@tds/core-colours";
import Box from "@tds/core-box";
import Link from "@tds/core-link";
import Responsive from "@tds/core-responsive";

import { SessionContext } from "../../../contexts";

import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { NavBarNotificationWidget } from "../../dashboard-widgets/notification-widget/NavBarNotificationWidget";

import { padds, devices } from "../../../style/global-style";
import { MenuItem } from "../../../datamodel/navigation";

const TopbarStyle = styled.div`
  color: ${(props) => props.color};
  background: ${colorTelusPurple};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1035;
  #user_languages_selector {
    color: white;
    cursor: pointer;
    margin-right: 1rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const TopbarContainer = styled.div`
  background: ${colorTelusPurple};
  height: 3.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  @media ${devices.mobileM} {
    justify-content: space-between;
  }

  @media ${devices.tablet} {
    padding-left: ${padds.LeftRightTablet};
    padding-right: ${padds.LeftRightTablet};
  }

  @media ${devices.laptop} {
    padding-left: ${padds.LeftRight};
    padding-right: ${padds.LeftRight};
  }
`;

const TopbarLeftSide = styled.div`
  display: none;
  @media ${devices.mobileM} {
    display: flex;
    align-items: center;
  }
`;

const TopbarRightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    text-decoration: none;
    font-size: 0.9rem;
  }
`;

export declare interface TopbarProps {
  customerSelector?: boolean;
  adminMenu?: MenuItem[];
  supportMenu?: MenuItem[];
  languageMenu?: MenuItem[];
}

export const Topbar: React.FC<TopbarProps> = (props) => {
  const { t, i18n } = useTranslation();

  const { session, setSession } = useContext(SessionContext);

  const changeLanguage = (language: string) => (event: Event) => {
    event.preventDefault();

    void i18n.changeLanguage(language);
  };

  const logout = (event: Event) => {
    event.preventDefault();

    setSession(null);
  };

  const profileMenu = [
    { label: t("Preferences"), link: "#/user/profile" } as MenuItem,
  ]
    .concat(
      (props.supportMenu ?? []).map((item) => {
        return { label: item.label, link: item.link };
      }),
    )
    .concat(
      (props.languageMenu ?? []).map((item) => {
        return { label: item.label, action: changeLanguage(item.link) };
      }),
    )
    .concat([{ label: t("Logout"), action: logout }]);
  const customerMenu = [
    { label: "Sherlock", link: "/c1" },
    { label: "Mockingbird", link: "/c2" },
  ];

  return (
    <TopbarStyle>
      <TopbarContainer>
        <TopbarLeftSide>
          {props.customerSelector && (
            <DropdownMenu
              theme="Customers"
              label="Choose Customer"
              menuItems={customerMenu}
            />
          )}
          {props.adminMenu && (
            <DropdownMenu
              id="top-admin-menu-dropdown"
              theme="Admin"
              label={t("Administration")}
              menuItems={props.adminMenu}
            />
          )}
        </TopbarLeftSide>
        <TopbarRightSide>
          <Box between={3} inline="{true}">
            {props.supportMenu && (
              <Responsive
                minWidth="md"
                render={() => (
                  <React.Fragment>
                    {props.supportMenu.map((item) => (
                      <Link invert href={item.link} target="_blank">
                        {item.label}
                      </Link>
                    ))}
                  </React.Fragment>
                )}
              />
            )}
            {props.languageMenu && (
              <Responsive
                minWidth="md"
                render={() => (
                  <React.Fragment>
                    {props.languageMenu.map((item) => (
                      <Link
                        id="user_languages_selector"
                        invert
                        onClick={changeLanguage(item.link)}
                      >
                        {item.label}
                      </Link>
                    ))}
                  </React.Fragment>
                )}
              />
            )}
          </Box>
          {session && (
            <NavBarNotificationWidget notifications={session?.notifications} />
          )}
          {session && (
            <DropdownMenu
              id="top-user-preferences-dropdown"
              alignRight={true}
              theme="User"
              label={session?.user?.displayName}
              menuItems={profileMenu}
            />
          )}
        </TopbarRightSide>
      </TopbarContainer>
    </TopbarStyle>
  );
};
