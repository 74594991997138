/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from "react";
import { I18nextProvider } from "react-i18next";
import { render, unmountComponentAtNode } from "react-dom";
import {
  IAugmentedJQuery,
  IComponentOptions,
  IHttpService,
  IRootScopeService,
} from "angular";
import NgComponent from "ngcomponent";
import { ThemeProvider } from "styled-components";

import sherlockTheme from "../style/sherlock-theme";
import i18n from "../i18n";
import { fromPairs } from "ramda";

import { Session } from "../datamodel/session";
import { RouteContext, SessionContext } from "../contexts";
import alliumTheme from "@telus-uds/theme-allium";
import { BaseProvider } from "@telus-uds/components-web";

let session: Session | null = null;

export default function asRootComponent(
  Class: React.ComponentType<unknown>,
  bindingNames?: string[],
  path?: string
): IComponentOptions {
  const names = bindingNames || [];

  return {
    bindings: fromPairs(names.map((_) => [_, "<"])),
    controller: [
      "$element",
      "$rootScope",
      "$http",
      class extends NgComponent {
        static get $$ngIsClass() {
          return true;
        }

        isDestroyed = false;

        constructor(
          private $element: IAugmentedJQuery,
          private $rootScope: IRootScopeService,
          private $http: IHttpService
        ) {
          super();

          // FIXME Only register one time
          $rootScope.$on("LanguageChanged", (event, language: string) => {
            if (i18n.language !== language) {
              void i18n.changeLanguage(language);
            }
          });

          // FIXME Only register one time
          i18n.on("languageChanged", (language) => {
            $rootScope.$apply(() => {
              $rootScope.$emit("ChangeLanguage", language);
            });
          });

          $rootScope.$on("SessionChanged", (event, newSession?: Session) => {
            if (session !== newSession) {
              this.setSession(newSession);
            }
          });
        }

        setSession = (newSession?: Session) => {
          if (session !== null && newSession === null) {
            this.$rootScope.$broadcast("Logout");
          }
          session = newSession;
          this.render();
        };

        render() {
          if (!this.isDestroyed) {
            render(
                <ThemeProvider theme={sherlockTheme}>
                  <BaseProvider defaultTheme={alliumTheme}>
                    <I18nextProvider i18n={i18n}>
                      <SessionContext.Provider
                        value={{ session, setSession: this.setSession }}
                      >
                        {/* FIXME when angular router is removed, replace with @reach/router or react-router */}
                        <RouteContext.Provider
                          value={{
                            getRoute: () => ({
                              location: window.location.hash.replace(/#/g, ""),
                              path,
                            }),
                          }}
                        >
                          <Class
                            {
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              ...(this.props as any)
                            }
                          />
                        </RouteContext.Provider>
                      </SessionContext.Provider>
                    </I18nextProvider>
                  </BaseProvider>
                </ThemeProvider>
              ,
              this.$element[0]
            );
          }
        }

        componentWillUnmount() {
          this.isDestroyed = true;
          unmountComponentAtNode(this.$element[0]);
        }
      },
    ],
  };
}
