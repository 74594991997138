const resources = {
  en: {
    translation: {
      SupportContact_msg:
        "If you have any queries regarding the portal please contact us at",
      Bytes_unit: "{{ value }} B",
      kiBytes_unit: "{{ value }} kB",
      MiBytes_unit: "{{ value }} MB",
      GiBytes_unit: "{{ value }} GB",
      TiBytes_unit: "{{ value }} TB",
      PiBytes_unit: "{{ value }} PB",
      Error: "Error",
      fqdn: "FQDN",
      "ip-netmask": "IP-Netmask",
      "ip-range": "IP-Range",
      "ip-wildcard": "IP-Wildcard",
      security: "Security",
      Success: "Success",
      Warning: "Warning",
      THREAT_HUNTING: "Threat Hunting",
    },
  },
  fr: {
    translation: {
      en: "fr",
      SupportContact_msg:
        "Pour toute question concernant le portail, veuillez communiquer avec nous au",
      Bytes_unit: "{{ value }} o",
      kiBytes_unit: "{{ value }} ko",
      MiBytes_unit: "{{ value }} Mo",
      GiBytes_unit: "{{ value }} Go",
      TiBytes_unit: "{{ value }} To",
      PiBytes_unit: "{{ value }} Po",
      ":": " :",
      About: "À propos",
      "Access Control List For": "Liste des contrôles d’accès pour",
      "Network Address Translation For":
        "Liste des traductions d’adresses de réseau pour",
      "Actions Legend": "Légende Des Actions",
      Active: "Actif",
      Appliances: "Appareils",
      "Alert Threshold": "Seuil D'Alerte",
      "All rights reserved.": "Tous droits réservés.",
      Analysis: "Analyse",
      "Back to Access Control List": "Retour à la liste des ACL",
      "Back to appliance list": "Retour à la liste des appareils",
      "An error occurred during the data fetch.":
        "Une erreur s'est produite lors de la récupération des données",
      "Block Threshold": "Seuil De Blockage",
      Consult: "Consulter",
      "Creation date": "Date de création",
      "Data Pattern": "Modèle De Données",
      "Decoder Action": "Action Du Décodeur",
      "Decoder Name": "Nom Du Décodeur",
      "Decoder Wildfire Action": "Décodeur D'Action Wildfire",
      "Destination Services": "Services De Destination",
      Details: "Détails",
      Deny: "Refuser",
      "Destination ports": "Ports destination",
      "Device Group": "Groupe d'équipement",
      Download: "Téléchargement",
      Download_action: "Télécharger",
      Drop: "Abandonner",
      Error: "Erreur",
      "File Types": "Types De Fichiers",
      "HA Group": "Groupe HD",
      "HA Members": "Membres HD",
      "Hide details": "Masquer les détails",
      "Hide security rules": "Cacher les règles de sécurité",
      HostName: "Hôte",
      "Host Type": "Type D'Hôte",
      Icon: "Icone",
      "IP Address": "Adresse IP",
      Interactive: "Interactif",
      Log: "Journaliser",
      "Modification date": "Date de modification",
      Loading: "Chargement",
      Logout: "Se déconnecter",
      "Log Severity": "Sévérité Du Journal",
      Manual: "Manuel",
      Members: "Membres",
      model: "Modèle",
      Name: "Nom",
      "Negated condition": "Condition inversée",
      "No item to display": "Aucun élement à afficher",
      Owner: "Propriétaire",
      Permit: "Autoriser",
      Preferences: "Préférences",
      Print_action: "Imprimer",
      Protocols: "Protocoles",
      "Rule Name": "Nom De Règle",
      Search: "Rechercher",
      "Security Profiles For": "Profils de sécurité pour",
      "Security Profile Name": "Nom Du Profil De Sécurité",
      "Security Profile Type": "Type DE Profil De Sécurité",
      "Security Rules": "Règles de sécurité",
      "Serial Number": "Numéro de série",
      Severity: "Sévérité",
      "Site Access": "Accès Aux Sites",
      Size: "Taille",
      "Source ports": "Ports source",
      Status: "Statut",
      Support: "Soutien",
      "Support License": "Licence de soutien",
      Success: "Succès",
      Synchronized: "Synchronisé",
      Technology: "Technologie",
      "This view is the last update as of today.":
        "Cette vue est la dernière mise à jour en date d'aujourd'hui.",
      "Threat Name": "Nom De La Menace",
      "Tunnel to a VPN": "Rediriger vers un réseau privé",
      UNKNOWN: "INCONNU",
      UNREACHABLE: "INACCESSIBLE",
      Users: "Utilisateurs",
      "User Credentials Submission": "Information D'Identification",
      "View ACL": "Afficher les ACL",
      "View IP Addresses": "Afficher les adresses IP",
      "View NAT": "Afficher les NAT",
      "View Policies": "Afficher les Politiques",
      Warning: "Avertissement",
      Customer: "Client",
      Shared: "Partagé",
      "API Status": "Statut de l'API",
      "General information": "Renseignements généraux",
      "Case Id:": "ID de cas :",
      "Case Source:": "Source du cas :",
      "Service Component:": "Composant de service :",
      "Time:": "Date :",
      "Last Modified:": "Dernière modification :",
      THREAT_HUNTING: "Chasse aux menaces",
      SOC: "COS",
      "Back to alerts": "Retour aux alertes",
      "New notification": "Nouvelle notification",
      "Go to notifications": "Aller aux notifications",
    },
  },
};

export default resources;
